import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getAutoNote} from 'actions/commentsActions';
import {updateSelectedItem} from 'actions/multiStepFormActions';
import Textarea from 'react-textarea-autosize';

import bugsnagClient from 'config/bugsnag';
import {reverseImmutable} from 'commons/commons';
import L18nText from 'components/shared/L18nText';
import Grid from 'components/shared/ui/Grid';
import Modal from 'components/shared/ui/Modal';
import Spinner from 'components/shared/ui/Spinner';
import DialogBox from 'components/shared/ui/DialogBox';
import {WineEssentials} from 'components/my-wines/partials';
import {prepareFields} from './notes';
import {tastingTypesWithDetailedNotes} from './notes';

import {getTastingType} from 'components/tasting/common';
import level3 from 'assets/json/tasting/level3.json';
import quick from 'assets/json/tasting/quick/quick.json';
import scholar2 from 'assets/json/tasting/scholar2/scholar2.json';
import scholar3 from 'assets/json/tasting/scholar3/scholar3.json';
import scholar4 from 'assets/json/tasting/scholar4/scholar4.json';

const flattenNotes = (notesLists) => Object.values(notesLists).flatMap((note) => note);

const hideAutoGenerateButton = [
	'scholar2',
	'scholar3',
	'scholar4',
	'scholar2m',
	'scholar3m',
	'scholar4m',
];

const getSource = {
	profound: level3,
	profoundMobile: level3,
	quick: quick,
	scholar2: scholar2,
	scholar2m: scholar2,
	scholar3: scholar3,
	scholar3m: scholar3,
	scholar4: scholar4,
	scholar4m: scholar4,
};

class TastingNoteStep extends Component {
	constructor(props) {
		super(props);
		this.state = {
			autoGenerateIsActive: true,
			error: false,
			isLoading: false,
			previousText: '',
		};
		this.autoGenerateCommentAreaText = this.autoGenerateCommentAreaText.bind(this);
		this.undoAutoGenerateCommentAreaText = this.undoAutoGenerateCommentAreaText.bind(this);
		this.updateSelectedItem = this.updateSelectedItem.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}

	prepareCharacteristics(notes, source) {
		const flattenedNotes = flattenNotes(notes);
		if (!Array.isArray(flattenedNotes)) return [];

		const characteristicKeys = [
			'sweetness__',
			'acidity__',
			'tannins__',
			'alcohol__',
			'body__',
			'mousse__',
			'flavourintensity__',
			'finish__',
		];

		const tastingSrc = getSource[source] || level3;

		const characteristicValues = {
			sweetness__: tastingSrc['sweetness__'],
			acidity__: tastingSrc['acidity__'],
			tannins__: tastingSrc['tannins__'],
			alcohol__: tastingSrc['alcohol__'],
			body__: tastingSrc['body__'],
			finish__: tastingSrc['finish__'],
			mousse__: tastingSrc['mousse__'],
			flavourintensity__: tastingSrc['flavourintensity__'],
		};

		let payload = [];

		characteristicKeys.map((characteristic) => {
			return (
				characteristicValues[characteristic] &&
				characteristicValues[characteristic]
					.filter((item, key) => flattenedNotes.includes(item))
					.forEach((item, key) => {
						let data = reverseImmutable(characteristicValues[characteristic]);

						payload.push({
							name: characteristic,
							steps: characteristicValues[characteristic].length,
							value: data.indexOf(item) + 1,
						});
					})
			);
		});

		return payload;
	}

	_addPrefix = (wineInformation) => {
		const notes = [];
		const addPrefixIn = ['nose', 'palate'];

		addPrefixIn.forEach((prefixWord) =>
			Object.values(wineInformation[prefixWord])
				.filter((item) => typeof item !== 'string')
				.forEach((item) => item.forEach((i) => notes.push(`${prefixWord}${i}`)))
		);

		return notes;
	};

	getTextAreaContent = () => {
		const {multiStepForm} = this.props;
		const commentsWrap = multiStepForm.selectedItems.comments
			? multiStepForm.selectedItems.comments
			: null;
		const textAreaContent =
			commentsWrap && commentsWrap.wine_summary ? commentsWrap.wine_summary : '';
		return textAreaContent;
	};

	getAutoGeneratedNotes = (wineInformation) => {
		const allNotes = [];
		const keys = ['appearance', 'palate', 'nose', 'observations'];

		const tasteData = keys.reduce((result, key) => ({...result, [key]: wineInformation[key]}), {});

		// returns list of string
		Object.values(tasteData).forEach((item) => allNotes.push(...Object.values(item)));

		const prefixAddedNotes = this._addPrefix(wineInformation);

		const noteList = allNotes.filter((note) => typeof note === 'string');
		const notes = noteList.concat(...prefixAddedNotes);

		return notes;
	};

	async autoGenerateCommentAreaText() {
		const {autoGenerateIsActive} = this.state;
		const {multiStepForm, getAutoNote, online} = this.props;

		this.setState({error: false});

		if (autoGenerateIsActive && online) {
			this.setState({
				autoGenerateIsActive: false,
				isLoading: true,
			});

			const notes = this.getAutoGeneratedNotes(multiStepForm.selectedItems);

			try {
				await getAutoNote(notes, this.updateSelectedItem, 'wine_summary');
				this.setState({
					autoGenerateIsActive: false,
					isLoading: false,
				});
			} catch (err) {
				this.setState({
					error: true,
					autoGenerateIsActive: true,
					isLoading: false,
				});
				bugsnagClient.notify(new Error('Failed to generate autonotes'), {
					metadata: {error: 'Failed to generate autonotes'},
				});
			}
		}
	}

	close = () => {
		this.setState({error: false});
	};

	undoAutoGenerateCommentAreaText() {
		this.setState({
			autoGenerateIsActive: true,
			previousText: '',
		});
		this.props.updateSelectedItem('comments', {wine_summary: this.state.previousText});
	}

	handleChange(event) {
		const target = event.target;
		const value = target.value;
		this.updateSelectedItem(value);
	}

	updateSelectedItem(value) {
		this.setState({isLoading: false, previousText: this.getTextAreaContent()});
		this.props.updateSelectedItem('comments', {wine_summary: value});
	}

	render() {
		const {
			online,
			multiStepForm: {selectedItems, tastingType},
		} = this.props;
		const {isLoading, error} = this.state;
		const scholarTastings = ['scholar4', 'scholar4Mobile', 'scholar3Mobile', 'scholar3'];
		let prepareOtherObservations;
		const type = getTastingType(tastingType);

		const allNotes = prepareFields(selectedItems, type);

		prepareOtherObservations =
			scholarTastings.includes(type) &&
			(selectedItems.palate.tasting_other_observation || selectedItems.nose.other_observations__)
				? selectedItems.palate.tasting_other_observation || selectedItems.nose.other_observations__
				: [];

		let controls = '';
		let autoGenerateBtnClasses = ['Button', 'Button__Outlined'];
		let showUndo = false;

		if (!this.state.autoGenerateIsActive) {
			showUndo = true;
			autoGenerateBtnClasses.push('Show_Button');
		}

		controls = (
			<div className="TastingNote__Buttons">
				{!isLoading && showUndo ? (
					<button
						className={autoGenerateBtnClasses.join(' ')}
						onClick={this.undoAutoGenerateCommentAreaText}
					>
						<L18nText id="tasting_undo" defaultMessage="Undo" />
					</button>
				) : (
					<span></span>
				)}
				<L18nText
					id="tasting_comments_button_title"
					defaultMessage="Please get online to autogenerate tasting note."
				>
					{(title) => (
						<button
							className={`Button Button__Default fade ${this.state.autoGenerateIsActive && 'show'}`}
							onClick={this.autoGenerateCommentAreaText}
							disabled={!this.state.autoGenerateIsActive || !online}
							data-toggle="tooltip"
							data-html="true"
							title={!this.props.online ? title : ''}
						>
							<L18nText id="tasting_note_auto_generate" defaultMessage="Auto generate" />
						</button>
					)}
				</L18nText>
			</div>
		);

		const placeholderId = isLoading ? 'app_loading' : 'tasting_note_textarea_placeholder';
		const placeholderText = isLoading ? 'Loading' : 'Enter your Tasting Note';

		let breadcrumb = '';
		breadcrumb = `tasting_observations / tasting_note`;
		if (tastingTypesWithDetailedNotes.includes(tastingType)) {
			breadcrumb = `${tastingType}_ / tasting_observations / tasting_note`;
		}

		return (
			<Grid columns={6}>
				<div className="TastingNote__Wrapper">
					{error && (
						<DialogBox
							title="app_oops"
							description="app_connection_interrupted"
							yesCallback={this.autoGenerateCommentAreaText}
							noCallback={this.close}
						/>
					)}
					<Modal.Breadcrumb path={breadcrumb} />
					<Modal.Title text="tasting_note" />
					{!hideAutoGenerateButton.includes(tastingType) && controls}
					<div className="TastingNote__TextArea TextArea__Wrapper">
						<L18nText id={placeholderId} defaultMessage={placeholderText}>
							{(placeholder) => (
								<Textarea
									data-test="TastingNote__input"
									value={this.getTextAreaContent()}
									onChange={this.handleChange}
									placeholder={placeholder}
								/>
							)}
						</L18nText>

						{hideAutoGenerateButton.includes(tastingType) && (
							<div className="WineDetails__Wrapper">
								<div className="WineDetails__Item">
									<div className="WineDetails__Text__Wrapper">
										<header>
											<L18nText id="app_notes" defaultMessage="Notes" />
										</header>
										<WineEssentials
											features={allNotes.notes}
											otherObservations={prepareOtherObservations}
											isStep={true}
										/>
									</div>
								</div>

								{/* Might be back in the future */}

								{/* {prepareCharacteristicsRating.length > 0 && (
									<div className="WineDetails__Item">
										<CharacteristicsSummary
											characteristics={prepareCharacteristicsRating}
											displayHeader={false}
										/>
									</div>
								)} */}
							</div>
						)}
						{isLoading && <Spinner />}
					</div>
				</div>
			</Grid>
		);
	}
}

function mapStateToProps(state) {
	const {
		multiStepForm,
		offline: {online},
	} = state;
	return {
		online,
		multiStepForm,
	};
}

export default connect(mapStateToProps, {getAutoNote, updateSelectedItem})(TastingNoteStep);
