const basicTrophies = [
	{
		id: 'By the Glass',
		name: 'By the Glass',
	},
	{
		id: 'Food match',
		name: 'Food match',
	},
	{
		id: 'Critics Choice',
		name: 'Critics Choice',
	},
	{
		id: 'Pub & Bar',
		name: 'Pub & Bar',
	},
];

const wineOfTheYearTrophies = [
	/*{
		id: 'House Wine of the Year',
		name: 'House Wine of the Year',
	},*/
	{
		id: 'Gastro Pub of the Year',
		name: 'Gastro Pub of the Year',
	},
	{
		id: 'Fine Wine of the Year',
		name: 'Fine Wine of the Year',
	},
	/*{
		id: 'Overall/Best in show',
		name: 'Overall/Best in show',
	},*/
	{
		id: 'None',
		name: 'None',
	},
];

const roundOneConclusion = [
	{
		id: 'Medal/In',
		name: 'Medal/In',
	},
	{
		id: 'Commended',
		name: 'Commended',
	},
	{
		id: 'Faulty',
		name: 'Faulty',
	},
	{
		id: 'Out',
		name: 'Out',
	},
	{
		id: 'No show',
		name: 'No show',
	},
];

const roundTwoConclusion = [
	{
		id: 'Gold',
		name: 'Gold',
	},
	{
		id: 'Silver',
		name: 'Silver',
	},
	{
		id: 'Bronze',
		name: 'Bronze',
	},
	{
		id: 'No Show',
		name: 'No Show',
	},
	{
		id: 'No Medal',
		name: 'No Medal',
	},
];

const allConclusion = [...roundOneConclusion, ...roundTwoConclusion];
const allTrophies = [...basicTrophies, ...wineOfTheYearTrophies];
const contestConstants = {
	relation: {
		NOT_LOADED: 'not_loaded',
		NONE: 'unrelated',
		LEADER: 'team_leader',
		OWNER: 'owner',
		ADMIN: 'admin',
		GUIDE: 'team_guide',
		MEMBER: 'team_member',
		PARTICIPANT: 'participant',
		REQUESTED_PARTICIPANT: 'requested_participant',
	},
	conclusion: allConclusion,
	roundOneConclusion,
	roundTwoConclusion,
	trophies: allTrophies,
	wineOfTheYearTrophies,

	FETCH_CONTEST_PENDING: 'FETCH_CONTEST_PENDING',
	FETCH_CONTEST_SUCCESS: 'FETCH_CONTEST_SUCCESS',
	FETCH_CONTEST_ERROR: 'FETCH_CONTEST_ERROR',

	FETCH_CONTEST_EXPORT_PENDING: 'FETCH_CONTEST_EXPORT_PENDING',
	FETCH_CONTEST_EXPORT_SUCCESS: 'FETCH_CONTEST_EXPORT_SUCCESS',
	FETCH_CONTEST_EXPORT_ERROR: 'FETCH_CONTEST_EXPORT_ERROR',

	FETCH_CONTEST_LOGISTICS_PENDING: 'FETCH_CONTEST_LOGISTICS_PENDING',
	FETCH_CONTEST_LOGISTICS_SUCCESS: 'FETCH_CONTEST_LOGISTICS_SUCCESS',
	FETCH_CONTEST_LOGISTICS_ERROR: 'FETCH_CONTEST_LOGISTICS_ERROR',

	FETCH_CONTEST_SET_DELIVERED_PENDING: 'FETCH_CONTEST_SET_DELIVERED_PENDING',
	FETCH_CONTEST_SET_DELIVERED_SUCCESS: 'FETCH_CONTEST_SET_DELIVERED_SUCCESS',
	FETCH_CONTEST_SET_DELIVERED_ERROR: 'FETCH_CONTEST_SET_DELIVERED_ERROR',

	FETCH_CONTEST_STATEMENT_SUMMARY_PENDING: 'FETCH_CONTEST_STATEMENT_SUMMARY_PENDING',
	FETCH_CONTEST_STATEMENT_SUMMARY_SUCCESS: 'FETCH_CONTEST_STATEMENT_SUMMARY_SUCCESS',
	FETCH_CONTEST_STATEMENT_SUMMARY_ERROR: 'FETCH_CONTEST_STATEMENT_SUMMARY_ERROR',

	SEARCH_CONTEST_PENDING: 'SEARCH_CONTEST_PENDING',
	SEARCH_CONTEST_SUCCESS: 'SEARCH_CONTEST_SUCCESS',
	SEARCH_CONTEST_ERROR: 'SEARCH_CONTEST_ERROR',

	UPDATE_CONTEST_TEAM_PENDING: 'UPDATE_CONTEST_TEAM_PENDING',
	UPDATE_CONTEST_TEAM_SUCCESS: 'UPDATE_CONTEST_TEAM_SUCCESS',
	UPDATE_CONTEST_TEAM_ERROR: 'UPDATE_CONTEST_TEAM_ERROR',

	UPDATE_CONTEST_ROLE_PENDING: 'UPDATE_CONTEST_ROLE_PENDING',
	UPDATE_CONTEST_ROLE_SUCCESS: 'UPDATE_CONTEST_ROLE_SUCCESS',
	UPDATE_CONTEST_ROLE_ERROR: 'UPDATE_CONTEST_ROLE_ERROR',

	FETCH_ADMIN_PROGRESS_PENDING: 'FETCH_ADMIN_PROGRESS_PENDING',
	FETCH_ADMIN_PROGRESS_SUCCESS: 'FETCH_ADMIN_PROGRESS_SUCCESS',
	FETCH_ADMIN_PROGRESS_ERROR: 'FETCH_ADMIN_PROGRESS_ERROR',

	FETCH_CONTEST_TEAM_LEADER_PENDING: 'FETCH_CONTEST_TEAM_LEADER_PENDING',
	FETCH_CONTEST_TEAM_LEADER_SUCCESS: 'FETCH_CONTEST_TEAM_LEADER_SUCCESS',
	FETCH_CONTEST_TEAM_LEADER_ERROR: 'FETCH_CONTEST_TEAM_LEADER_ERROR',

	FETCH_CONTEST_REQUEST_PENDING: 'FETCH_CONTEST_REQUEST_PENDING',
	FETCH_CONTEST_REQUEST_SUCCESS: 'FETCH_CONTEST_REQUEST_SUCCESS',
	FETCH_CONTEST_REQUEST_ERROR: 'FETCH_CONTEST_REQUEST_ERROR',

	FETCH_CONTENT_STATS_PENDING: 'FETCH_CONTENT_STATS_PENDING',
	FETCH_CONTENT_STATS_ERROR: 'FETCH_CONTENT_STATS_ERROR',
	FETCH_CONTENT_STATS_SUCCESS: 'FETCH_CONTENT_STATS_SUCCESS',

	UPDATE_TEAM_STATEMENT_PENDING: 'UPDATE_TEAM_STATEMENT_PENDING',
	UPDATE_TEAM_STATEMENT_ERROR: 'UPDATE_TEAM_STATEMENT_ERROR',
	UPDATE_TEAM_STATEMENT_SUCCESS: 'UPDATE_TEAM_STATEMENT_SUCCESS',

	UPDATE_CONTEST_STATEMENT_PENDING: 'UPDATE_CONTEST_STATEMENT_PENDING',
	UPDATE_CONTEST_STATEMENT_ERROR: 'UPDATE_CONTEST_STATEMENT_ERROR',
	UPDATE_CONTEST_STATEMENT_SUCCESS: 'UPDATE_CONTEST_STATEMENT_SUCCESS',

	UPDATE_ADMIN_CONTEST_STATEMENT_PENDING: 'UPDATE_ADMIN_CONTEST_STATEMENT_PENDING',
	UPDATE_ADMIN_CONTEST_STATEMENT_ERROR: 'UPDATE_ADMIN_CONTEST_STATEMENT_ERROR',
	UPDATE_ADMIN_CONTEST_STATEMENT_SUCCESS: 'UPDATE_ADMIN_CONTEST_STATEMENT_SUCCESS',

	UPDATE_PARTICIPANT_METADATA_PENDING: 'UPDATE_PARTICIPANT_METADATA_PENDING',
	UPDATE_PARTICIPANT_METADATA_ERROR: 'UPDATE_PARTICIPANT_METADATA_ERROR',
	UPDATE_PARTICIPANT_METADATA_SUCCESS: 'UPDATE_PARTICIPANT_METADATA_SUCCESS',

	ADD_USER_CONTEST_PENDING: 'ADD_USER_CONTEST_PENDING',
	ADD_USER_CONTEST_ERROR: 'ADD_USER_CONTEST_ERROR',
	ADD_USER_CONTEST_SUCCESS: 'ADD_USER_CONTEST_SUCCESS',

	REJECT_USER_CONTEST: 'REJECT_USER_CONTEST',

	UPDATE_ACTIVE_THEME: 'UPDATE_ACTIVE_THEME',
};

export default contestConstants;
