const eventsConstants = {
	ADD_EVENT_IMAGE_REJECTED: 'ADD_EVENT_IMAGE_REJECTED',
	ADD_EVENT_IMAGE_SUCCESS: 'ADD_EVENT_IMAGE_SUCCESS',

	FETCH_EVENTS: 'FETCH_EVENTS',
	FETCH_EVENTS_PENDING: 'FETCH_EVENTS_PENDING',
	FETCH_EVENTS_FULFILLED: 'FETCH_EVENTS_FULFILLED',
	FETCH_EVENTS_REJECTED: 'FETCH_EVENTS_REJECTED',
	FETCH_FEATURED_EVENTS_PENDING: 'FETCH_FEATURED_EVENTS_PENDING',
	FETCH_FEATURED_EVENTS_FULFILLED: 'FETCH_FEATURED_EVENTS_FULFILLED',
	FETCH_FEATURED_EVENTS_REJECTED: 'FETCH_FEATURED_EVENTS_REJECTED',
	FETCH_MY_EVENTS: 'FETCH_MY_EVENTS',
	FETCH_MY_EVENTS_PENDING: 'FETCH_MY_EVENTS_PENDING',
	FETCH_MY_EVENTS_FULFILLED: 'FETCH_MY_EVENTS_FULFILLED',
	FETCH_MY_EVENTS_REJECTED: 'FETCH_MY_EVENTS_REJECTED',
	FETCH_SELECTED_EVENT: 'FETCH_SELECTED_EVENT',
	FETCH_SELECTED_EVENT_PENDING: 'FETCH_SELECTED_EVENT_PENDING',
	FETCH_SELECTED_EVENT_FULFILLED: 'FETCH_SELECTED_EVENT_FULFILLED',
	FETCH_SELECTED_EVENT_REJECTED: 'FETCH_SELECTED_EVENT_REJECTED',
	SEARCH_EVENTS: 'SEARCH_EVENTS',
	SEARCH_EVENTS_PENDING: 'SEARCH_EVENTS_PENDING',
	SEARCH_EVENTS_FULFILLED: 'SEARCH_EVENTS_FULFILLED',
	SEARCH_EVENTS_REJECTED: 'SEARCH_EVENTS_REJECTED',

	CREATE_PENDING: 'CREATE_EVENT_PENDING',
	CREATE_SUCCESS: 'CREATE_EVENT_FULFILLED',
	CREATE_ERROR: 'CREATE_EVENT_ERROR',

	UPDATE_PENDING: 'UPDATE_EVENT_PENDING',
	UPDATE_SUCCESS: 'UPDATE_EVENT_FULFILLED',
	UPDATE_ERROR: 'UPDATE_EVENT_ERROR',

	ATTEND_EVENT_PENDING: 'ATTEND_EVENT_PENDING',
	ATTEND_EVENT_FULFILLED: 'ATTEND_EVENT_FULFILLED',
	ATTEND_EVENT_REJECTED: 'ATTEND_EVENT_REJECTED',
	ATTEND_EVENT_REDIRECT_OWNER: 'ATTEND_EVENT_REDIRECT_OWNER',
	RESET_EVENT_SHOWCASE: 'RESET_EVENT_SHOWCASE',
	SET_TASTING_SHOWCASE_DATA: 'SET_TASTING_SHOWCASE_DATA',

	SB_MY_EVENTS: 'SB_MY_EVENTS',

	FETCH_TEAMS_FULFILLED: 'FETCH_TEAMS_FULFILLED',
	FETCH_TEAMS_PENDING: 'FETCH_TEAMS_PENDING',

	SET_EVENT_PREVENT_RELOAD: 'SET_EVENT_PREVENT_RELOAD',
};

export default eventsConstants;
