const appConstants = {
	PRODUCTION_URL: 'swa.noteable.co',
	MAX_PRICE_RANGE: 999999,
	SUBSCRIBE: 'subscribe',
	SUBSCRIBE_NEW: 'new',

	CONTACT_EMAIL_ADDRESS: 'contact@noteable.co',

	SET_ACTIVE_FORM: 'SET_ACTIVE_FORM',

	SERVER_ERROR: 'SERVER_ERROR',
	CLOSE_SIDENAV: 'CLOSE_SIDENAV',
	SAVE_COMPLETE: 'SAVE_COMPLETE',
	SET_NAV_OFFCANVAS: 'SET_NAV_OFFCANVAS',
	CLEAR_AFTER_SAVE: 'CLEAR_AFTER_SAVE',
	CLEAR_SERVER_ERROR: 'CLEAR_SERVER_ERROR',
	SET_ADVANCED_OPTIONS: 'SET_ADVANCED_OPTIONS',

	RRS_DISMISS_SNACK: 'RRS_DISMISS_SNACK',
	OPEN_APP_ERROR_MODAL: 'OPEN_APP_ERROR_MODAL',
	TOGGLE_APP_ERROR_MODAL: 'TOGGLE_APP_ERROR_MODAL',
	DISMISS_APP_ERROR_MODAL: 'DISMISS_APP_ERROR_MODAL',

	STORE_ERROR_LOG: 'STORE_ERROR_LOG',
	READ_ERROR_LOG: 'READ_ERROR_LOG',

	APP_ERROR: 'APP_ERROR',
	PRESERVE_URL: 'PRESERVE_URL',

	SET_VOUCHER: 'SET_VOUCHER',
	SET_PAYMENT_MODE: 'SET_PAYMENT_MODE',
	SET_SUBSCRIPTION_URL: 'SET_SUBSCRIPTION_URL',
	SET_SUBSCRIPTION_TYPE: 'SET_SUBSCRIPTION_TYPE',
	SET_SUBSCRIPTION_DURATION: 'SET_SUBSCRIPTION_DURATION',
	SET_SKIP_SELECT_PLAN: 'SET_SKIP_SELECT_PLAN',
	REMOVE_SUBSCRIPTION_URL: 'REMOVE_SUBSCRIPTION_URL',
	SET_CURRENT_STATE: 'SET_CURRENT_STATE',

	FETCH_NAVBAR: 'FETCH_NAVBAR',
	FETCH_NAVBAR_PENDING: 'FETCH_NAVBAR_PENDING',
	FETCH_NAVBAR_FULFILLED: 'FETCH_NAVBAR_FULFILLED',
	FETCH_NAVBAR_REJECTED: 'FETCH_NAVBAR_REJECTED',

	SHOW_SUBSCRIPTION_WARNING: 'SHOW_SUBSCRIPTION_WARNING',
};

export default appConstants;
