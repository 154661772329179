import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Button from 'components/shared/ui/Button';
import '../contest.scss';

class Back extends Component {
	onBack = (e) => {
		//this.props.history.goBack();
		e.persist();
		e.nativeEvent.stopImmediatePropagation();
		e.stopPropagation();

		let dirs = (this.props.history.location.pathname + '/').split('/').filter(Boolean);
		dirs.pop();
		this.props.history.push(this.props.to || '/' + dirs.join('/'));
	};
	render() {
		return (
			<Button variant="outlined" size="small" onHandleClick={this.onBack}>
				<div className="link-mouse">&lt; Back</div>
			</Button>
		);
	}
}

export default withRouter(Back);
