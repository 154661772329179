import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';

import Grid from 'components/shared/ui/Grid';
import {routeConstants, appConstants} from 'const';

import './Dashboard.scss';

interface OwnProps {
	history: any;
}

interface StateProps {
	app: any;
	subscription: any;
	user: any;
	activePlan: string;
}

type Props = OwnProps & StateProps;

class Dashboard extends Component<Props> {
	render() {
		const {activePlan} = this.props;

		if (activePlan === appConstants.SUBSCRIBE) {
			return <Redirect to={routeConstants.SUBSCRIPTION} />;
		}

		return (
			<div className="Dashboard__Container">
				<Grid columns={8}>
					<Link to={routeConstants.NEW_TASTING} className="Dashboard__Link">
						New Tasting
					</Link>
					<Link to={routeConstants.MY_TASTINGS} className="Dashboard__Link">
						My Tastings
					</Link>
					<Link to={routeConstants.EVENTS} className="Dashboard__Link">
						Events
					</Link>
					<Link to="https://noteable.co/tools-old/" target="_blank" className="Dashboard__Link">
						About
					</Link>
				</Grid>
			</div>
		);
	}
}

function mapStateToProps(state: StateProps) {
	return {
		app: state.app,
		subscription: state.app.subscription,
		user: state.user,
		activePlan: state.user.activePlan,
	};
}

export default connect(mapStateToProps)(Dashboard);
