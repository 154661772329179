import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App';
import {store, persistor} from './store';

// Styles
import 'bootstrap/dist/css/bootstrap.css';
import 'mdbreact/dist/css/mdb.css';

console.warn('Build:', 'bcd4fa58');

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<Switch>
					<Route path="/" component={App} />
				</Switch>
			</BrowserRouter>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

// Register the service worker
serviceWorkerRegistration.register({
	onUpdate: (registration: any) => {
		if (window.confirm('New version available! Refresh to update?')) {
			const waitingServiceWorker = registration.waiting;

			if (waitingServiceWorker) {
				waitingServiceWorker.addEventListener('statechange', (event: any) => {
					if (event.target.state === 'activated') {
						window.location.reload();
					}
				});
				waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
			}
		}
	},
});
