import React, {Component} from 'react';
import {connect} from 'react-redux';

import {routeConstants} from 'const';
import Grid from 'components/shared/ui/Grid';
import {fetchWines} from 'actions/wineActions';
import EditWine from 'components/events/editWine';
import DropoverLink from 'components/shared/ui/DropoverLink';

import '../../wineList/WineList.scss';
import {withRouter} from 'react-router';
import {getLinkWithArguments} from 'commons/commons';
import {
	makeContestInfoSelector,
	makeSelectedThemeSelector,
	contestThemesLoadingSelector,
} from 'reducers/contestsReducer/contestInfoReducer';
import Back from '../common/Back';

class FlightManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'add',
			selectedWine: {},
			selectedRef: '',
			selectedTheme: '',
		};
	}

	componentDidMount() {
		this.initWines();
	}
	onBack = () => {
		this.props.history.goBack();
	};
	goToWineDetails = (wineRef) => {
		this.props.history.replace(getLinkWithArguments(routeConstants.TASTING_RESULT_REF, {wineRef}));
	};

	goToEditWine = (wine) => {
		this.setState({showAddWine: true, selectedWine: wine, type: wine ? 'edit' : 'add'});
	};

	async initWines() {
		const sortBy = this.props.wines.sortBy;
		await this.props.fetchWines(sortBy, this.props.history);
	}

	onCloseHandle = () => {
		this.setState({showAddWine: false});
	};

	getLabel = (selected) => {
		if (selected) {
			return selected;
		}

		if (this.props.selectedTheme) {
			return this.props.selectedTheme;
		}

		return `Please start by selecting one`;
	};

	onChangeTheme = (theme) => {
		this.setState({selectedTheme: theme.name});
		this.setState({selectedRef: theme.ref});
	};

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	render() {
		const {showAddWine, selectedWine, type} = this.state;
		const {themes, loadingState, contest} = this.props;
		const {alias} = contest || {};
		const {selectedTheme, selectedRef} = this.state;

		const themeAlias = alias && alias.theme ? alias.theme : 'theme';

		return (
			<div className="contest WineTable__Wrapper">
				{showAddWine && (
					<EditWine onClose={this.onCloseHandle} wine={selectedWine} type={type} themes={themes} />
				)}
				<Grid columns={12}>
					<Back />
				</Grid>
				<Grid columns={12}>
					<h1>Flight Management</h1>
				</Grid>
				<Grid columns={12}>
					<div className="WineList__Selector">
						{themeAlias}:{' '}
						<DropoverLink
							label={'Select ' + themeAlias}
							loadingState={loadingState}
							options={themes}
							value={selectedTheme}
							displayValue={(selected) => this.getLabel(selected)}
							onSelect={this.onChangeTheme}
						/>
					</div>
				</Grid>
				<Grid columns={12}>
					<div>
						<div className="WineList__Header">
							<div className="WineList__Name">Name</div>
							<div className="WineList__Name">Alias</div>
							<div className="WineList__Country">Country</div>
							<div className="WineList__Country">Region</div>
							<div className="WineList__Vintage">Vintage</div>
							<div className="WineList__Vintage">Price</div>
							<div className="WineList__Producer">Grape</div>
						</div>
						<div className="WineList__Container">
							{this.props.wines.data
								.filter((wine) => wine.collection === selectedRef)
								.map((item, index) => (
									<div
										onClick={() => this.goToEditWine(item)}
										className="WineList__Wrapper"
										key={`wine-${index}`}
									>
										<div className="WineList__Name">{item.name ? item.name : '-'}</div>
										<div className="WineList__Name">{item.alias ? item.alias : '-'}</div>
										<div className="WineList__Country">{item.country ? item.country : '-'}</div>
										<div className="WineList__Country">{item.region ? item.region : '-'}</div>
										<div className="WineList__Vintage">{item.vintage ? item.vintage : '-'}</div>
										<div className="WineList__Vintage">{item.price ? item.price : '-'}</div>
										<div className="WineList__Producer">{item.grape ? item.grape : '-'}</div>
									</div>
								))}
						</div>
					</div>
				</Grid>
			</div>
		);
	}
}

function mapStateToProps(state, props) {
	const getContestInfo = makeContestInfoSelector();
	const contestRef = props.match.params.ref;
	const getSelectedTheme = makeSelectedThemeSelector();
	const themes = state.contests.contestInfo.allContests[contestRef]?.collections || [];

	return {
		app: state.app,
		wines: state.wines,
		user: state.user,
		contest: getContestInfo(state, {contestRef}),
		themes: themes,
		selectedTheme: getSelectedTheme(state, {contestRef}),
		loadingState: contestThemesLoadingSelector(state, {contestRef}),
	};
}

export default connect(mapStateToProps, {fetchWines})(withRouter(FlightManagement));
