const multiStepFormConstants = {
	INIT_STEP_DATA: 'INIT_STEP_DATA',
	UPDATE_STEP_SELECTIONS: 'UPDATE_STEP_SELECTIONS',

	UPDATE_SELECTED_ITEM: 'UPDATE_SELECTED_ITEM',
	PRESET_INFO: 'PRESET_INFO',
	REMOVE_SELECTED_ITEM: 'REMOVE_SELECTED_ITEM',
	UPDATE_PROGRESSBAR: 'UPDATE_PROGRESSBAR',

	COPY_AROMA_NOTES: 'COPY_AROMA_NOTES',
	NAVIGATE_FORM: 'NAVIGATE_FORM',

	SUBMIT_START: 'MULTI_FORM_SUBMIT_START',
	SUBMIT_FULFILLED: 'MULTI_FORM_SUBMIT_FULFILLED',
	SUBMIT_ERROR: 'MULTI_FORM_SUBMIT_ERROR',
	INIT_TASTING_SOURCE: 'INIT_TASTING_SOURCE',

	NAVIGATE_AWAY: 'NAVIGATE_AWAY',

	RESET_FORM: 'RESET_FORM',
	RESET_SESSION: 'RESET_SESSION',
	RESTART_SESSION: 'RESTART_SESSION',
	RESTORE_SESSION: 'RESTORE_SESSION',
	SET_TASTING_TYPE: 'SET_TASTING_TYPE',
	EVENT_TASTING_INIT_STEP: 'EVENT_TASTING_INIT_STEP',

	SELECT_IMPRESSION_FOR_EDITING: 'SELECT_IMPRESSION_FOR_EDITING',
};

export default multiStepFormConstants;
