import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Button from 'components/shared/ui/Button';
import '../contest.scss';

class Back extends Component {
	onBack = (e) => {
		e.persist();
		e.nativeEvent.stopImmediatePropagation();
		e.stopPropagation();
		this.props.history.push('/events');
	};
	render() {
		return (
			<Button variant="outlined" size="small" onHandleClick={this.onBack}>
				<div className="link-mouse">&lt; Back</div>
			</Button>
		);
	}
}

export default withRouter(Back);
