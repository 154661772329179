import React, {Component} from 'react';
import XLSX from 'xlsx';
import {connect} from 'react-redux';
import {FaSync} from 'react-icons/fa';
import {
	contestThemesLoadingSelector,
	makeContestInfoSelector,
} from 'reducers/contestsReducer/contestInfoReducer';
import {contestConstants} from 'const';
import {fetch2ndRequestedBottle, onSetDelievered} from 'actions/contestActions';

import NotFound from './common/NotFound';
import PageUnavailable from './common/PageUnavailable';
import {getCurrentUserRole} from './common';

import Button from 'components/shared/ui/Button';
import Spinner from 'components/shared/ui/Spinner';
import Checkbox from 'components/shared/ui/Checkbox';
import Back from './common/Back';
import './contest.scss';
import './Logistics.scss';
import {contestLogisticsLoadingSelector} from 'reducers/logisticsReducer';
import Tune from '../../assets/audio/Ring.mp3';

class Logistics extends Component {
	state = {};

	onFetchBottles = async () => {
		const {ref} = this.props.match.params;

		await this.props.fetch2ndRequestedBottle(ref);
	};

	componentDidMount() {
		this.audio = new Audio(Tune);
		this.audio.loop = true;
		this.onFetchBottles();

		this.intervalId = setInterval(this.onFetchBottles, 30 * 1000);
	}

	componentDidUpdate() {
		if (this.props.logisticsData?.data?.filter((item) => !item.delivered)?.length) {
			if (this.audio.paused) {
				this.audio.play().catch((err) => console.error(`Error playing audio file: ${err}`));
			}
		} else {
			this.audio.pause();
			this.audio.currentTime = 0;
		}
	}

	componentWillUnmount() {
		this.audio.pause();
		this.audio.currentTime = 0;

		clearInterval(this.intervalId);
	}

	render() {
		let {contest, resultsState, loadingState} = this.props;
		const {ref} = this.props.match.params;

		const isLoading = loadingState === 'loading' || resultsState === 'loading';

		if (!contest) {
			return <NotFound />;
		}

		const userRole = getCurrentUserRole(contest?.user_relations);

		if (![contestConstants.relation.OWNER, contestConstants.relation.LEADER].includes(userRole)) {
			return <PageUnavailable />;
		}

		if (isLoading) return <Spinner />;

		return (
			<div className="contest">
				<Back />
				<Button
					className="corner-button"
					variant="outlined"
					size="small"
					onHandleClick={() => {
						this.onFetchBottles();
					}}
				>
					Refresh <FaSync />
				</Button>
				<h1>Logistics Dashboard</h1>
				<table>
					<thead>
						<tr>
							<th>Request for 2nd Wine</th>
							<th>Requested Time</th>
							<th>Delivered Time</th>
							<th>Delivered</th>
						</tr>
					</thead>
					<tbody>
						{this.props.logisticsData?.data?.map((bottle, key) => (
							<tr key={`bottle=${key}`}>
								<td>{bottle?.name}</td>
								<td>{bottle?.requested_at}</td>
								<td>{bottle?.delivered ? bottle?.delivered_at : ''}</td>
								<td>
									<Checkbox
										value={bottle?.delivered ? true : false}
										onChange={async () => {
											!bottle?.delivered && (await this.props.onSetDelievered(ref, bottle?.id));
										}}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const getContestInfo = makeContestInfoSelector();
	const contestRef = props.match.params.ref;

	return {
		contest: getContestInfo(state, {contestRef}),
		loadingState: contestThemesLoadingSelector(state, {contestRef}),
		resultsState: contestLogisticsLoadingSelector(state),
		logisticsData: state.logisticsData,
	};
};

const mapDispatchToProps = {
	fetch2ndRequestedBottle,
	onSetDelievered,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logistics);
