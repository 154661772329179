import {connect} from 'react-redux';
import React, {Component} from 'react';
import SearchBar from 'components/shared/ui/SearchBar';

import WineInfo from './WineInfo';
import Footer from './Footer';

import './EditWine.scss';
import DialogBox from 'components/shared/ui/DialogBox';
import {filterWines} from 'components/shared/ui/WineFilter/functions';
import {updateWine} from 'actions/wineActions';

const wines = [
	{producer: "Mikkel's Wine", region: 'Toscana de Fredericia', country: 'Italy'},
	{producer: "Another Mikkel's Wine", region: 'Toscana de Fredericia', country: 'Spain'},
	{producer: "Mikkel's Wine", region: 'Toscana de Fredericia', country: 'Japan'},
	{producer: "Mikkel's Wine", region: 'Toscana de Fredericia', country: 'Italy'},
	{producer: "Another Mikkel's Wine", region: 'Toscana de Fredericia', country: 'Spain'},
	{producer: "Mikkel's Wine", region: 'Toscana de Fredericia', country: 'Japan'},
];

class EditWine extends Component {
	state = {
		step1: true,
		selectedWine: {},
		wineList: wines,
		search: '',
	};

	componentDidMount() {
		const {wine, type} = this.props;

		this.setState({selectedWine: wine});

		if (type === 'edit') {
			this.setState({step1: false});
		}
	}

	onFilter = (search) => {
		const list = filterWines(wines, search);

		this.setState({wineList: list});
	};

	onItemClick = (item) => {
		this.setState({step1: false});
		this.setState({
			selectedWine: item,
		});
	};

	onBackClick = () => {
		this.setState({step1: true});
		this.setState({wineList: wines});
		this.setState({search: ''});
	};

	onUpdateFields = (value, id) => {
		const {selectedWine} = this.state;
		this.setState({
			selectedWine: {
				...selectedWine,
				[id]: value,
			},
		});
	};

	onEditWine = () => {
		this.props.updateWine(this.state.selectedWine.ref, this.state.selectedWine);
		this.props.onClose();
	};

	render() {
		const {type, themes} = this.props;
		const {step1, selectedWine = {}} = this.state;

		const footer = (
			<Footer
				disabled={selectedWine && !selectedWine.name}
				type={type}
				step={step1 ? 'step1' : 'step2'}
				onClose={this.props.onClose}
				onBackClick={this.onBackClick}
				onEditWine={this.onEditWine}
			/>
		);

		return (
			<DialogBox customFooter={footer} width={step1 ? 8 : 6} noCallback={this.props.onClose}>
				<div className="EditWine__Title">Edit wine details</div>
				<WineInfo
					onBackClick={this.onBackClick}
					wine={selectedWine}
					type={type}
					themes={themes}
					onUpdateFields={this.onUpdateFields}
				/>
				{/* <div>{JSON.stringify(this.state.selectedWine)}</div> */}
			</DialogBox>
		);
	}
}

function mapStateToProps(state) {
	return {
		app: state.app,
		wines: state.wines,
		events: state.events,
	};
}

export default connect(mapStateToProps, {
	updateWine,
})(EditWine);
