import {contestConstants} from 'const';
import {createLoadingSelector} from './requestStatusReducer';

const defaultData = {
	data: [],
};

export default function reducer(state = defaultData, action) {
	switch (action.type) {
		case contestConstants.FETCH_CONTEST_EXPORT_PENDING:
		case contestConstants.FETCH_CONTEST_EXPORT_ERROR: {
			let data = Object.assign({}, state, {data: []});

			return data;
		}

		case contestConstants.FETCH_CONTEST_EXPORT_SUCCESS: {
			let data = Object.assign({}, state, {data: action.payload.data});

			return data;
		}

		default:
			return state;
	}
}

export const contestExportsLoadingSelector = createLoadingSelector('FETCH_CONTEST_EXPORT');
