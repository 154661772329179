const winesConstants = {
	CLOSE_ERROR_MODAL: 'CLOSE_ERROR_MODAL',

	FETCH_WINES: 'FETCH_WINES',
	FETCH_WINES_PENDING: 'FETCH_WINES_PENDING',
	FETCH_WINES_FULFILLED: 'FETCH_WINES_FULFILLED',
	FETCH_WINES_REJECTED: 'FETCH_WINES_REJECTED',
	FETCH_SELECTED_WINE: 'FETCH_SELECTED_WINE',
	FETCH_SELECTED_WINE_PENDING: 'FETCH_SELECTED_WINE_PENDING',
	FETCH_SELECTED_WINE_FULFILLED: 'FETCH_SELECTED_WINE_FULFILLED',
	FETCH_SELECTED_WINE_REJECTED: 'FETCH_SELECTED_WINE_REJECTED',

	UPDATE_WINE: 'UPDATE_WINE',
	UPDATE_WINE_PENDING: 'UPDATE_WINE_PENDING',
	UPDATE_WINE_FULFILLED: 'UPDATE_WINE_FULFILLED',
	UPDATE_WINE_REJECTED: 'FETCH_WINES_REJECTED',

	CLEAR_WINE_EVENT: 'CLEAR_WINE_EVENT',
	FETCH_WINE_EVENT_PENDING: 'FETCH_WINE_EVENT_PENDING',
	FETCH_WINE_EVENT_REJECTED: 'FETCH_WINE_EVENT_REJECTED',
	FETCH_WINE_EVENT_FULFILLED: 'FETCH_WINE_EVENT_FULFILLED',

	SEARCH_WINES: 'SEARCH_WINES',
	SORT_WINES: 'SORT_WINES',
	FILTER_WINES: 'FILTER_WINES',

	SB_MY_WINES: 'SB_MY_WINES',
	SAVE_COLLAPSE_STATE: 'SAVE_COLLAPSE_STATE',
};

export default winesConstants;
