import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FaClipboardList, FaCheck, FaPercent} from 'react-icons/fa';
import {FaSync} from 'react-icons/fa';

import CategoryList from './CategoryList';
import Button from 'components/shared/ui/Button';
import Checkbox from 'components/shared/ui/Checkbox';
import {getLinkWithArguments, isProductionSite} from 'commons/commons';

import L18nText from 'components/shared/L18nText';
import {fetchContest, fetchTeamProgress, setActiveTheme} from 'actions/contestActions';
import {makeContestInfoSelector} from 'reducers/contestsReducer/contestInfoReducer';
import {getContestProgressSelector} from 'reducers/contestsReducer/contestProgressReducer';
import {contestConstants, routeConstants} from 'const';
import NotFound from '../common/NotFound';
import PageUnavailable from '../common/PageUnavailable';
import {getCurrentUserRole} from '../common';
import Back from '../common/Back';

import '../contest.scss';
import './ContestProgress.scss';

class Progress extends Component {
	componentDidMount() {
		this.onFetchData();
		this.onInputChange(this.state.filter, this.state.checkStatus);
	}

	state = {
		checkStatus: {
			'not started': false,
			'in progress': false,
			'all teams done': true,
			today: true,
		},
		filter: '',
	};

	get contestRef() {
		return this.props.match.params.ref;
	}

	onFetchData = async () => {
		const {ref} = this.props.match.params;
		const {contest = {}} = this.props;

		if (!contest) {
			await this.props.fetchContest(ref);
		}

		window.requestAnimationFrame(() => this.fetchProgress());
	};

	navigateToTheme = (theme) => {
		this.props.setActiveTheme(this.contestRef, theme);
		const {ref} = this.props.match.params;
		const url = getLinkWithArguments(routeConstants.CONTEST_ASSESSMENT, {
			ref,
		});
		this.props.history.push(`${url}?from=progress`);
	};

	fetchProgress = async () => {
		const {ref: contestRef} = this.props.match.params;
		const {contest = {}} = this.props;
		// Fetch data for SUP
		contest.teams &&
			this.props.fetchTeamProgress(contestRef, contestRef, this.getAnimation({ref: contestRef}));

		contest.teams.map(async (team) => {
			this.props.fetchTeamProgress(contestRef, team.ref, this.getAnimation(team));
		});
	};

	getAnimation = (team) => {
		let title = document.getElementById(`title-${team.ref}`);
		let spinner = document.getElementById(`spinner-${team.ref}`);

		if (!(title && spinner)) {
			return {};
		}

		return {
			cb1: () => {
				title.classList.remove('show');
				spinner.classList.add('show');
			},
			cb2: () => {
				title.classList.add('show');
				spinner.classList.remove('show');
			},
		};
	};

	onInputChange = (ev, checkStatus) => {
		let find = ev || '';
		let findList = Object.keys(checkStatus)
			.filter((key) => checkStatus[key])
			.filter((el) => el !== 'today');
		let rows = document.getElementsByClassName('filter-me');
		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			if (
				findList.some((el) => rows[i].dataset.find?.includes(el)) &&
				(!checkStatus.today || rows[i].dataset.find?.includes('today')) &&
				rows[i].dataset.find?.includes(find)
			)
				row.classList.remove('hide');
			else row.classList.add('hide');
		}
		//document.querySelectorAll("filter-me:not(.hide)")
		document.getElementById('number-of-rows').innerText = document.querySelectorAll(
			'.filter-me:not(.hide)'
		).length;

		//onChange?.(ev);
	};

	nameMustContain = (str) => {
		if (1 || !isProductionSite()) return true;
		// prettier-ignore
		const filtering = ['HW-R','OFS-L','OFS-MAD'];

		for (let i = 0; i < filtering.length; i++) {
			if (str.includes(`[${filtering[i]}]`)) return true;
		}

		return false;
	};

	onBack = () => {
		this.props.setActiveTheme(this.contestRef, '');
		this.props.history.goBack();
	};

	render() {
		const {contest, progressDetails = {}} = this.props;

		if (!contest) {
			return <NotFound />;
		}

		const userRole = getCurrentUserRole(contest && contest.user_relations);

		if (userRole !== contestConstants.relation.OWNER) {
			return <PageUnavailable />;
		}

		return (
			<div class="contest -progress">
				<Back />
				<Button
					className="corner-button"
					variant="outlined"
					size="small"
					onHandleClick={this.onFetchData}
				>
					Refresh <FaSync />
				</Button>{' '}
				<h1>Event progress for {contest.name} </h1>
				<div className="flex start" style={{gap: '36px'}}>
					<div>
						<hr />
						<div className="flex start" style={{paddingRight: '40px'}}>
							<span className="SearchBar__Wrapper">
								<input
									autoComplete="off"
									className="SearchBar__Default"
									onChange={(ev) => {
										this.setState({filter: ev.target.value});
										this.onInputChange(ev.target.value, this.state.checkStatus);
									}}
									onClick={(ev) => {
										this.setState({filter: ev.target.value});
										this.onInputChange(ev.target.value, this.state.checkStatus);
									}}
									id="searchInput"
									placeholder="Filter"
									style={{'max-width': '375px'}}
									value={this.state.filter}
								/>
							</span>
							<Button
								variant="outlined"
								size="small"
								onHandleClick={() => {
									let el = document.getElementById('searchInput');
									el.value = '';
									el.click();
								}}
							>
								Reset filter
							</Button>
						</div>
					</div>
					<div>
						<b>Filter flights & teams</b>
						<div className="flex start" style={{gap: '24px'}}>
							<Checkbox
								label="Not Started"
								onChange={() => {
									let checkStatus = {
										...this.state.checkStatus,
										'not started': !this.state.checkStatus['not started'],
									};
									this.setState({
										checkStatus: checkStatus,
									});
									this.onInputChange(this.state.filter, checkStatus);
								}}
								value={this.state.checkStatus['not started']}
							/>
							<Checkbox
								label="Done"
								onChange={() => {
									let checkStatus = {
										...this.state.checkStatus,
										'all teams done': !this.state.checkStatus['all teams done'],
									};
									this.setState({
										checkStatus: checkStatus,
									});
									this.onInputChange(this.state.filter, checkStatus);
								}}
								value={this.state.checkStatus['all teams done']}
							/>
							<Checkbox
								label="Progress"
								onChange={() => {
									let checkStatus = {
										...this.state.checkStatus,
										'in progress': !this.state.checkStatus['in progress'],
									};
									this.setState({
										checkStatus: checkStatus,
									});
									this.onInputChange(this.state.filter, checkStatus);
								}}
								value={this.state.checkStatus['in progress']}
							/>
							<Checkbox
								label="Today"
								onChange={() => {
									let checkStatus = {
										...this.state.checkStatus,
										today: !this.state.checkStatus['today'],
									};
									this.setState({
										checkStatus: checkStatus,
									});
									this.onInputChange(this.state.filter, checkStatus);
								}}
								value={this.state.checkStatus['today']}
							/>
						</div>
					</div>
				</div>
				<CategoryList
					name={contest.name}
					teams={contest.teams}
					navigateToTheme={this.navigateToTheme}
					themes={contest.themes.filter(this.nameMustContain)}
					contest={contest}
					progressDetails={progressDetails}
					fetchProgress={this.fetchProgress}
					checkStatus={this.state.checkStatus}
				/>
				<div className="mx-10 flex" style={{'justify-content': 'space-evenly'}}>
					<span className="no-wrap">
						<FaClipboardList /> <L18nText id="contest_category_notStarted" message="Not started" />
					</span>
					<span className="no-wrap">
						<FaPercent /> How much completed
					</span>
					<span className="no-wrap">
						<FaCheck /> <L18nText id="contest_category_done" message="Done" />
					</span>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, props) {
	const contestRef = props.match.params.ref;
	const getContestInfo = makeContestInfoSelector();

	return {
		contest: getContestInfo(state, {contestRef}),
		progressDetails: getContestProgressSelector(state),
	};
}

export default connect(mapStateToProps, {
	fetchContest,
	setActiveTheme,
	fetchTeamProgress: fetchTeamProgress,
})(Progress);
