import React, {Component} from 'react';
import {withRouter} from 'react-router';

import {FaPencilAlt, FaSync} from 'react-icons/fa';
import Tooltip from 'components/shared/ui/Tooltip';
import {normalizeStatement, canonicalText} from '../helpers';
import InlineSVG from 'svg-inline-react';
import Spinner from 'components/shared/ui/Spinner';
import startCase from 'lodash/startCase';
import sortBy from 'lodash/sortBy';
import {getCurrency, getLinkWithArguments} from 'commons/commons';
import {routeConstants} from 'const';
import {selectImpressionForEditing} from 'actions/multiStepFormActions';
import {connect} from 'react-redux';

function getSvgHeadliner(str) {
	const svgSource = `<svg height="90" width="15"  transform="rotate(180)">
<text x="-15" y="15" fill="currentColor" transform="rotate(90 0,15)" style="font-style:normal">${str}</text>
</svg>`;
	return <InlineSVG src={svgSource} />;
}

const ratings = [
	getSvgHeadliner('BALANCE'),
	getSvgHeadliner('FINISH'),
	getSvgHeadliner('INTENSITY'),
	getSvgHeadliner('COMPLEXITY'),
	getSvgHeadliner('TYPICITY'),
	getSvgHeadliner('MATURITY'),
	getSvgHeadliner('DRIKABILITY'),
	getSvgHeadliner('VALUE/$€£'),
];

const notes = ['TASTING NOTE', 'FOOD PAIRING NOTE'];

class NormalJudgeDashboard extends Component {
	getFlightCode = (tastingName) => {
		const flight_pattern = /\d+-\d+-\d+-\d+/;
		const match = tastingName.match(flight_pattern);
		if (match) {
			const tmp = match[0].split('-');
			return parseInt(tmp[3], 10);
		} else return Infinity;
	};

	fraction2level = (item, context = '') => {
		if (typeof item !== 'number') {
			return '-';
		}

		const circle = (size) => {
			return <span className={'rating-circle -' + size}>{size}</span>;
		};

		if (item < 0.3) {
			return this.tooltip(circle('L'), 'Low ' + context);
		} else if (item > 0.7) {
			return this.tooltip(circle('H'), 'High ' + context);
		}

		return this.tooltip(circle('M'), 'Medium ' + context);
	};

	longText = (strOri) => {
		let str = strOri.replace('\r', '').replace(/\n{3,}/g, '\n\n');

		str = str.split('\n\n').map((item, key) => {
			return (
				<>
					{!!key && (
						<>
							<br />
							<br />
						</>
					)}
					{item}
				</>
			);
		});

		return <div className="long_text_wrapper">{str}</div>;
	};

	tooltip = (content, tip) => {
		if (!content) {
			return '';
		}

		if (!tip) {
			return content;
		}

		tip = tip.replace('_', ' ').trim();

		tip = tip.charAt(0).toUpperCase() + tip.slice(1);

		return <Tooltip text={tip}>{content}</Tooltip>;
	};

	updatePayload = (data) => {
		const {
			balance,
			finish,
			intensity,
			complexity,
			typicity,
			maturity,
			drinkability,
			value_for_money,
			medal,
		} = data.info;

		const foodPairing = data.food_pairing && this.longText(data.food_pairing);

		const tastingNote = this.longText(
			[data.summary_wine, data.summary_personal].filter(Boolean).join(' ')
		);

		let displayMedal = null;
		if (medal) {
			const hintText = `Medal: ${startCase(medal.replace('_', ' '))}`;

			displayMedal = (
				<Tooltip text={hintText}>
					<span className="ring strong" data-content={canonicalText(medal)}>
						{normalizeStatement(medal)}
					</span>
				</Tooltip>
			);
		}

		return {
			balance: this.fraction2level(balance, 'balance'),
			finish: this.fraction2level(finish, 'finish'),
			intensity: this.fraction2level(intensity, 'intensity'),
			complexity: this.fraction2level(complexity, 'complexity'),
			typicity: this.fraction2level(typicity, 'typicity'),
			maturity: this.fraction2level(maturity, 'maturity'),
			drinkability: this.fraction2level(drinkability, 'drinkabillity'),
			value_for_money: this.fraction2level(value_for_money, 'value for money'),
			tastingNote,
			foodPairing,
			medal: displayMedal || '-',
		};
	};

	handleEdit = (impression) => {
		const {data} = impression;
		const {source, ref} = data;

		if (source !== 'swa20') {
			return;
		}

		this.props.selectImpressionForEditing(data);
		this.props.history.push(
			getLinkWithArguments(routeConstants.EDIT_TASTING, {
				ref,
				type: source,
			})
		);
	};

	render() {
		const {
			subjects,
			participants,
			onFetchContestTeam,
			isRound2,
			loadingStatement,
			loadingTeamLeader,
		} = this.props;
		const price = (subject) => getCurrency(subject?.data?.price, subject?.data?.currency);

		return (
			<div className="ContestProgress__Wrapper">
				<div className="Contest_Table_Scroll extra-spacing">
					<table>
						<thead>
							<tr>
								<th className="flex min-w-m">
									<span
										onClick={onFetchContestTeam}
										className={`link-mouse fade ${
											0 && loadingStatement === 'loading' ? '' : 'show'
										}`}
										style={{color: '#f5f4ef4a', padding: '5px 10px'}}
									>
										{loadingTeamLeader === 'loading' ? <Spinner small light /> : <FaSync />}
									</span>
								</th>
								{ratings.map((column) => (
									<th>{column}</th>
								))}
								{notes.map((column) => (
									<th style={{'text-align': 'left'}}>
										<div className="long_text_wrapper">{column}</div>
									</th>
								))}
								<th>{getSvgHeadliner('MEDAL')}</th>
							</tr>
						</thead>
						<tbody>
							{subjects &&
								sortBy(subjects, (subject) => this.getFlightCode(subject.data.name)).map(
									(subject, index) =>
										subject.impressions.length ? (
											<>
												<tr key={`tasting-${index}`}>
													<td
														colSpan={2 + ratings.length + notes.length}
														style={{borderTop: '2px solid'}}
													>
														<div className="Contest_Table_Normal_Head">
															<h6>
																{[
																	subject.data.name,
																	subject.data.vintage,
																	subject.data.region,
																	subject.data.country,
																	price(subject),
																]
																	.filter(Boolean)
																	.join(', ')}
															</h6>
															<span onClick={() => this.handleEdit(subject.impressions[0])}>
																<FaPencilAlt />
															</span>
														</div>
													</td>
												</tr>
												{subject.impressions.map((impression) => {
													const payload = this.updatePayload(impression.data);
													return (
														<tr key={impression.data.ref}>
															<td></td>
															{Object.entries(payload).map((el) => (
																<>
																	{'medal' === el[0] ? (
																		<td className="text-center">{el[1] || '-'}</td>
																	) : (
																		<td>{el[1] || '-'}</td>
																	)}
																</>
															))}
														</tr>
													);
												})}
											</>
										) : (
											<></>
										)
								)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {};
}

const mapDispatchToProps = {
	selectImpressionForEditing,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NormalJudgeDashboard));
