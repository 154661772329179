/* global globalThis */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import './SubscriptionWelcomeMessage.scss';
import Grid from '../../shared/ui/Grid';
import L18nText from 'components/shared/L18nText';
import {ReactComponent as Stars} from './Stars.svg';
import Button from 'components/shared/ui/Button';
//import {onRedirectToEvent} from 'commons/shared';
import {routeConstants} from 'const';

class SubscriptionWelcomeMessage extends Component {
	componentWillUnmount() {
		globalThis && globalThis.window && globalThis.window.scrollTo(0, 0);
	}

	componentDidMount() {
		let event = 'CompleteRegistration';
		if (globalThis && globalThis.fbq) {
			globalThis.fbq('track', event);
			console.dir(event + ' OK');
		} else console.dir(event + ' not OK');
	}

	render() {
		const {activePlan, futurePlan} = this.props;
		const hasFreeUpgrade = activePlan === 'pro' && futurePlan === 'basic';
		// const originalPlan = futurePlan || activePlan;
		// const activePlanName = <L18nText id={`subscription_plan_${originalPlan}`} />;

		return (
			<div className="GetStart_Container_Subscription">
				<Grid columns={12}>
					<div className="GetStart_Wrap">
						<div className="GetStart__Upper">
							<div className="GetStart__Header">
								<div className="GetStart__Title">
									<L18nText id="start_thank_you" defaultMessage="Thank you so much ..." />
								</div>
							</div>
						</div>
						<div className="GetStart_Wrapper">
							<div>
								<L18nText id="start_how_intro" defaultMessage="Thank you very much" />{' '}
							</div>
							<div>
								<L18nText id="start_how_to_start" defaultMessage="Here’s how to get started!" />
							</div>
							<p>Happy tastings</p>

							<div className="GetStarted__Footer">
								<Link to={routeConstants.NEW_TASTING} className="GetStart_Link">
									<Button variant="reverse" infoKey="nav_newTasting" data-test="nav_newTasting">
										<L18nText id="tasting_nav_start" defaultMessage="New tasting" />
									</Button>
								</Link>
							</div>
						</div>
					</div>
				</Grid>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		activePlan: state.user.activePlan,
		futurePlan: state.user.futurePlan,
	};
}

export default connect(mapStateToProps)(SubscriptionWelcomeMessage);
