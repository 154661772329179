export const roles = [
	{
		id: 'member',
	},
	{
		id: 'guide',
	},
	{
		id: 'leader',
	},
];
