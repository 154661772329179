import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import NotFound from '../common/NotFound';
import {getLinkWithArguments} from 'commons/commons';
import {routeConstants} from 'const';
import ApproveImg from '../../../assets/images/approve.png';
import TeamImg from '../../../assets/images/team.png';
import ProgressImg from '../../../assets/images/progress.png';
import MedalImg from '../../../assets/images/medal.png';
import DownloadImg from '../../../assets/images/download.png';
import FinalImg from '../../../assets/images/final.png';
import HelpImg from '../../../assets/images/help.png';
import FlightImg from '../../../assets/images/flight.png';

import './ContestAdmin.scss';
import {FaClipboardList} from 'react-icons/fa';

class ContestAdmin extends Component {
	render() {
		const {contest} = this.props;

		if (!contest) {
			return <NotFound />;
		}

		const {
			contest: {ref, name},
		} = this.props;

		return (
			<div className="contest-admin">
				<p className="admin-header">
					{/* As {contest?.alias?.admin || 'Admin'} for {name} you have access to the following: */}
					Click to view details on approval of judges, arrival to contest, allocate team and their
					role, see progress of the contest, follow status on medal and trophies awarded and
					evaluate the entire tasing in the final assesment overview.
				</p>

				<div className="admin-panel">
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_ARRIVAL, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={ApproveImg} alt="approve" />
							<p>Approve & Arrival of judges</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_DISTRIBUTION, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={TeamImg} alt="approve" />
							<p>Team distribution</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_PROGRESS, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={ProgressImg} alt="approve" />
							<p>Event progress</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_RESULT, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={MedalImg} alt="approve" />
							<p>Medals & Trophies awarded</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_FLIGHT_MANAGEMENT, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={FlightImg} alt="approve" />
							<p>Flight Management</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_ASSESSMENT, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={FinalImg} alt="approve" />
							<p>Final assesment on all tastings</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_RESULT, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={DownloadImg} alt="approve" />
							<p>Download data</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_EXPORT, {
							ref,
						})}
					>
						<div className="admin-item">
							<img src={DownloadImg} alt="approve" />
							<p>Advanced Export</p>
						</div>
					</Link>
					<Link
						to={getLinkWithArguments(routeConstants.CONTEST_LOGISTICS_DASHBOARD, {
							ref,
						})}
					>
						<div className="admin-item">
							<FaClipboardList size={40} color="black" />
							<p>Logistics Dashboard</p>
						</div>
					</Link>
					<a href="https://noteable.co/contact/" target="_blank">
						<div className="admin-item">
							<img src={HelpImg} alt="approve" />
							<p>Contact</p>
						</div>
					</a>
				</div>
			</div>
		);
	}
}

export default ContestAdmin;
