import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useLocation, useHistory, Redirect} from 'react-router-dom';
import {registerUser, loginUser, loginGoogleUser, clearData, closeWelcomeMessage} from 'actions/userActions';
import {userConstants, routeConstants} from 'const';

const GoogleCallback = ({registerUser, loginUser, loginGoogleUser, clearData, isAuthenticated, serverUrl}) => {
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		const handleCallback = async () => {
			const params = new URLSearchParams(location.search);
			const code = params.get('code');

			if (code) {
				try {
					const response = await fetch(`${serverUrl}/auth/google/callback`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({code}),
					});

					const data = await response.json();

					if (data && data.user) {
						const {email, name, id: password, title} = data.user; // Adjust this to match your user object structure

						try {
							// Try logging the user in first
							if (!data.indentity) {
								throw new Error("Invalid user data received from Google callback: 'indentity' property missing");
							}
							await loginGoogleUser(email, data.indentity);
							history.replace(`${routeConstants.HOME}${window.location.search}`);
						} catch (loginError) {
							console.error('Error during login:', loginError);

							try {
								// If login fails, register the user
								await registerUser(password, {
									email,
									name,
									wine_knowledge: title,
									voucher: 'USC001', // Adjust as needed
								});

								// Log the user in after successful registration
								await loginUser(email, password);
								history.replace(`${routeConstants.HOME}${window.location.search}`);
							} catch (registerError) {
								if (registerError.response && registerError.response.status === 409) {
									// Assuming 409 status code for email already registered
									// Email already registered, attempt to login
									try {
										await loginUser(email, password);
										history.replace(`${routeConstants.HOME}${window.location.search}`);
									} catch (error) {
										console.error('Error during login after registration error:', error);
										clearData();
									}
								} else {
									console.error('Error during registration:', registerError);
									clearData();
								}
							}
						}
					} else {
						console.error('Invalid user data received from Google callback:', data);
					}
				} catch (error) {
					console.error('Error handling Google callback:', error);
					clearData();
				}
			}
		};

		handleCallback();
	}, [location, registerUser, loginUser, loginGoogleUser, history, clearData]);

	if (isAuthenticated) {
		return <Redirect to={`${routeConstants.HOME}${window.location.search}`} />;
	}

	return <div>Loading...</div>;
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.user.isLoggedIn,
	serverUrl: state.app.advancedOptions.serverUrl,
});

export default connect(mapStateToProps, {
	registerUser,
	loginUser,
	loginGoogleUser,
	clearData,
	closeWelcomeMessage,
})(GoogleCallback);
