const color = {
	nuance_pink: '#f95a79',
	nuance_lemon: '#feff6f',
	nuance_lemongreen: '#f5fecd',
	nuance_gold: '#ffe655',
	nuance_amber: '#ffbe18',
	nuance_orange: '#f86f57',
	nuance_onionskin: '#ea5e36',
	nuance_purple: '#b4085c',
	nuance_ruby: '#d20457',
	nuance_salmon: '#f86a68',
	nuance_garnet: '#d60f12',
	nuance_tawny: '#a83119',
	nuance_brown: '#a54916',
	nuance_brown_red: '#a54916',
};

const colorintensity_deep = {
	nuance_pink: '#ff5475',
	nuance_lemon: '#feff6f',
	nuance_lemongreen: '#f6ffcc',
	nuance_gold: '#ffe655',
	nuance_amber: '#ffbe18',
	nuance_orange: '#ff6a50',
	nuance_onionskin: '#ff5221',
	nuance_purple: '#bc005c',
	nuance_ruby: '#d60056',
	nuance_salmon: '#ff6361',
	nuance_garnet: '#e50003',
	nuance_tawny: '#bb2406',
	nuance_brown: '#b84403',
};

const colorintensity_medium = {
	nuance_pink: '#f0637e',
	nuance_lemon: '#f7f876',
	nuance_lemongreen: '#f3fbd0',
	nuance_gold: '#f7e05e',
	nuance_amber: '#f3b924',
	nuance_orange: '#ef7560',
	nuance_onionskin: '#df6441',
	nuance_purple: '#ab115c',
	nuance_ruby: '#c70f59',
	nuance_salmon: '#f07270',
	nuance_garnet: '#cb1a1d',
	nuance_tawny: '#9e3723',
	nuance_brown: '#9c4c1f',
};

const colorintensity_pale = {
	nuance_pink: '#d77c8e',
	nuance_lemon: '#e2e28c',
	nuance_lemongreen: '#eff4d7',
	nuance_gold: '#ddce77',
	nuance_amber: '#d1aa46',
	nuance_orange: '#d5887a',
	nuance_onionskin: '#be7762',
	nuance_purple: '#8e2e5d',
	nuance_ruby: '#a72f5f',
	nuance_salmon: '#d88988',
	nuance_garnet: '#a83d3e',
	nuance_tawny: '#814b40',
	nuance_brown: '#80543b',
};

const colorHazy = {
	nuance_pink: 'radial-gradient(ellipse at center, #cc072e 0%, #f95a79 100%)',
	nuance_lemon: 'radial-gradient(ellipse at center, #edef00 0%, #feff6f 100%)',
	nuance_lemongreen: 'radial-gradient(ellipse at center, #dcfc50 0%, #f5fecd 100%)',
	nuance_gold: 'radial-gradient(ellipse at center, #d5b500 0%, #ffe655 100%)',
	nuance_amber: 'radial-gradient(ellipse at center, #986d00 0%, #ffbe18 100%)',
	nuance_orange: 'radial-gradient(ellipse at center, #c72508 0%, #f86f57 100%)',
	nuance_onionskin: 'radial-gradient(ellipse at center, #912c0f 0%, #ea5e36 100%)',
	nuance_purple: 'radial-gradient(ellipse at center, #3a031e 0%, #b4085c 100%)',
	nuance_ruby: 'radial-gradient(ellipse at center, #550223 0%, #d20457 100%)',
	nuance_salmon: 'radial-gradient(ellipse at center, #d70d0a 0%, #f86a68 100%)',
	nuance_garnet: 'radial-gradient(ellipse at center, #5f0708 0%, #d60f12 100%)',
	nuance_tawny: 'radial-gradient(ellipse at center, #391108 0%, #a83119 100%)',
	nuance_brown: 'radial-gradient(ellipse at center, #351707 0%, #a54916 100%)',
};

export const selectedColor = {
	nuance_red: '#c83232',
	nuance_rose: '#FFAA9A',
	nuance_white: '#f0ffb4',
	color_biological_aged: '#FFE17F',
	color_oxidative_aged: '#A1510D',
	nuance_pinkorange: '#f97976',
	nuance_pink: '#f95a79',
	nuance_lemon: '#feff6f',
	nuance_lemongreen: '#f5fecd',
	nuance_gold: '#ffe655',
	nuance_amber: '#ffbe18',
	nuance_orange: '#f86f57',
	nuance_onionskin: '#ea5e36',
	nuance_purple: '#b4085c',
	nuance_ruby: '#d20457',
	nuance_salmon: '#f86a68',
	nuance_garnet: '#d60f12',
	nuance_tawny: '#a83119',
	nuance_brown: '#a54916',
	nuance_brown_red: '#a54916',

	type_rose: '#FFAA9A',
	type_red: '#c83232',
	type_white: '#f0ffb4',

	clarity_hazy: colorHazy,
	clarity_clear: color,

	colorintensity_pale: colorintensity_pale,
	colorintensity_medium: colorintensity_medium,
	colorintensity_deep: colorintensity_deep,
};
