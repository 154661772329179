import React, {Component} from 'react';
import XLSX from 'xlsx';
import {connect} from 'react-redux';
import {FaSync, FaDownload} from 'react-icons/fa';
import {
	makeContestInfoSelector,
	contestThemesLoadingSelector,
} from 'reducers/contestsReducer/contestInfoReducer';
import {contestConstants} from 'const';
import {fetchContestToExport} from 'actions/contestActions';
import {contestExportsLoadingSelector} from 'reducers/exportReducer';

import NotFound from './common/NotFound';
import PageUnavailable from './common/PageUnavailable';
import {getCurrentUserRole} from './common';

import Button from 'components/shared/ui/Button';
import Checkbox from 'components/shared/ui/Checkbox';
import Spinner from 'components/shared/ui/Spinner';
import Back from './common/Back';
import './contest.scss';
import './Exports.scss';

class ContestExports extends Component {
	state = {
		exportField: {
			'Wine Name': false,
			'Flight Code': false,
			Team: false,
			Category: false,
			Trophy: false,
			'Judge Name': false,
			'Judge e-mail': false,
			'Judge tasting note': false,
			'Best note': false,
			'Judge Medal': false,
			'Team conclusion': false,
			'Head Judge Conclusion': false,
			Region: false,
			Country: false,
			Vintage: false,
			'Grape(s)': false,
			Price: false,
			Currency: false,
			Producer: false,
			'Food pairing note': false,
			Acidity: false,
			Alcohol: false,
			Body: false,
			Tanins: false,
			Sweetness: false,
			Descriptors: false,
			'Start date': false,
			'End date': false,
			Quality: false,
			Slider: false,
			Readiness: false,
			'2nd Bottle Requested': false,
		},
	};

	onFetchExports = async () => {
		const {ref} = this.props.match.params;

		await this.props.fetchContestToExport(ref);
	};

	componentDidMount() {
		this.onFetchExports();
	}

	getFlightCode = (name) => {
		const flight_pattern = /\d+-\d+-\d+-\d+/;
		const match = name.match(flight_pattern);
		if (match) return match[0];
		else return '-';
	};

	getSlider = (slider) => {
		return Object.keys(slider)
			.slice(1, -1)
			.map((key) => `${key}: ${slider[key]}`)
			.join(', ');
	};

	onDownload = () => {
		if (!Object.keys(this.state.exportField).filter((key) => this.state.exportField[key]).length)
			return;

		let filename = `${this.props.contest.name} results ${new Date().toISOString()}`;

		let data = this.props.exportData?.map((data) =>
			data?.impressions.map((impression) => {
				let row = {};
				this.state.exportField['Wine Name'] && (row['Wine Name'] = impression.name);
				this.state.exportField['Flight Code'] &&
					(row['Flight Code'] = this.getFlightCode(impression.name));
				this.state.exportField.Team &&
					(row.Team =
						this.props.contest.teams.find((team) => team.ref === impression.team)?.name ?? '-');
				this.state.exportField.Category &&
					(row.Category = this.props.contest.collections.find(
						(collection) => collection.ref === impression.collection
					)?.name);
				this.state.exportField['Judge Name'] && (row['Judge Name'] = impression.creator.name);
				this.state.exportField['Judge e-mail'] && (row['Judge e-mail'] = impression.creator.email);
				this.state.exportField['Judge tasting note'] &&
					(row['Judge tasting note'] = impression.summary_personal ?? '-');
				this.state.exportField['Best note'] &&
					(row['Best note'] = data.statements[0]?.metadata?.marked_statements?.includes(
						impression.ref
					)
						? 'Yes'
						: 'No');
				this.state.exportField['Judge Medal'] &&
					(row['Judge Medal'] = `${impression.info.medal}${
						data.statements[0]?.metadata?.marked_statements?.includes(impression.ref) ? ' !' : ''
					}`);
				this.state.exportField['Team conclusion'] &&
					(row['Team conclusion'] = data?.statements[0]?.statement ?? '-');
				this.state.exportField['Head Judge Conclusion'] &&
					(row['Head Judge Conclusion'] = data?.statements[1]?.statement ?? '-');
				this.state.exportField.Region && (row.Region = impression.region);
				this.state.exportField.Country && (row.Country = data?.mold?.country ?? '-');
				this.state.exportField.Vintage && (row.Vintage = impression.vintage);
				this.state.exportField['Grape(s)'] && (row['Grape(s)'] = impression.grape);
				this.state.exportField.Price && (row.Price = impression.price);
				this.state.exportField.Currency && (row.Currency = impression.currency);
				this.state.exportField.Producer && (row.Producer = impression.producer);
				this.state.exportField['Food pairing note'] &&
					(row['Food pairing note'] = impression.food_pairing);
				this.state.exportField.Acidity && (row.Acidity = impression.notes['@'][0]);
				this.state.exportField.Alcohol && (row.Alcohol = impression.notes['@'][1]);
				this.state.exportField.Body && (row.Body = impression.notes['@'][2]);
				this.state.exportField.Tanins &&
					(row.Tanins = impression.notes['@'][impression.notes['@'].length - 1]);
				this.state.exportField.Sweetness &&
					(row.Sweetness = impression.notes['@'][impression.notes['@'].length - 2]);
				this.state.exportField.Descriptors && (row.Descriptors = impression.notes['@'].join(', '));
				this.state.exportField['Start date'] &&
					(row['Start date'] = this.props.contest.collections.find(
						(collection) => collection.ref === impression.collection
					)?.start_date);
				this.state.exportField['End date'] &&
					(row['End date'] = this.props.contest.collections.find(
						(collection) => collection.ref === impression.collection
					)?.end_date);
				this.state.exportField.Quality &&
					(row.Quality = impression.notes['@'][impression.notes['@'].length - 4]);
				this.state.exportField.Slider && (row.Slider = this.getSlider(impression.info));
				this.state.exportField.Readiness &&
					(row.Readiness = impression.notes['@'][impression.notes['@'].length - 3]);
				this.state.exportField['2nd Bottle Requested'] &&
					(row['2nd Bottle Requested'] = data.statements[0]?.requested ? 'Yes' : 'No');

				return row;
			})
		);

		const ws = XLSX.utils.json_to_sheet(data?.flat());
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'Noteable');

		XLSX.writeFile(wb, `${filename}.xlsx`);
	};

	onInputChange = (value) => {
		let rows = document.getElementsByClassName('filter-export');
		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			if (row.innerHTML.includes(value)) row.classList.remove('hide');
			else row.classList.add('hide');
		}
	};

	getTrophy = (statement) => {
		let trophies = [
			statement?.extra_a,
			statement?.extra_b,
			statement?.extra_c,
			statement?.extra_d,
			statement?.extra_e,
		];
		return trophies.filter(Boolean).join(', ');
	};

	render() {
		let {contest, exportData, loadingState, resultsState} = this.props;

		const isLoading = loadingState === 'loading' || resultsState === 'loading';

		if (!contest) {
			return <NotFound />;
		}

		const userRole = getCurrentUserRole(contest?.user_relations);

		if (![contestConstants.relation.OWNER, contestConstants.relation.LEADER].includes(userRole)) {
			return <PageUnavailable />;
		}

		if (isLoading) return <Spinner />;

		return (
			<div className="contest">
				<Back />
				<Button
					className="corner-button"
					variant="outlined"
					size="small"
					onHandleClick={() => this.onFetchExports()}
				>
					Refresh <FaSync />
				</Button>
				<div className="flex start">
					<h1>Advanced Export</h1>
					<div className="flex start" style={{paddingRight: '40px'}}>
						<span className="SearchBar__Wrapper">
							<input
								autoComplete="off"
								className="SearchBar__Default"
								onChange={(ev) => {
									this.onInputChange(ev.target.value);
								}}
								onClick={(ev) => {
									this.onInputChange(ev.target.value);
								}}
								id="filterInput"
								placeholder="Filter"
								style={{'max-width': '375px'}}
							/>
						</span>
						<Button
							variant="outlined"
							size="small"
							onHandleClick={() => {
								let el = document.getElementById('filterInput');
								el.value = '';
								el.click();
							}}
						>
							Reset filter
						</Button>
					</div>
				</div>
				<hr />
				<div className="export_fields">
					<b>Winedata</b>
					<div className="flex start export_options">
						<Checkbox
							label="Winename"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Wine Name': !this.state.exportField['Wine Name'],
									},
								})
							}
						/>
						<Checkbox
							label="Region"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Region: !this.state.exportField['Region'],
									},
								})
							}
						/>
						<Checkbox
							label="Grape(s)"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Grape(s)': !this.state.exportField['Grape(s)'],
									},
								})
							}
						/>
						<Checkbox
							label="Currency"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Currency: !this.state.exportField['Currency'],
									},
								})
							}
						/>
						<Checkbox
							label="Country"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Country: !this.state.exportField['Country'],
									},
								})
							}
						/>
						<Checkbox
							label="Price"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Price: !this.state.exportField['Price'],
									},
								})
							}
						/>
						<Checkbox
							label="Vintage"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Vintage: !this.state.exportField['Vintage'],
									},
								})
							}
						/>
						<Checkbox
							label="Producer"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Producer: !this.state.exportField['Producer'],
									},
								})
							}
						/>
					</div>
				</div>
				<hr />
				<div className="export_fields">
					<b>Tasting details</b>
					<div className="flex start export_options">
						<Checkbox
							label="Acidity"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Acidity: !this.state.exportField['Acidity'],
									},
								})
							}
						/>
						<Checkbox
							label="Tanins"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Tanins: !this.state.exportField['Tanins'],
									},
								})
							}
						/>
						<Checkbox
							label="Slider"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Slider: !this.state.exportField['Slider'],
									},
								})
							}
						/>
						<Checkbox
							label="Food paring note"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Food paring note': !this.state.exportField['Food paring note'],
									},
								})
							}
						/>
						<Checkbox
							label="Alcohol"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Alcohol: !this.state.exportField['Alcohol'],
									},
								})
							}
						/>
						<Checkbox
							label="Sweetness"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Sweetness: !this.state.exportField['Sweetness'],
									},
								})
							}
						/>
						<Checkbox
							label="Readiness"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Readiness: !this.state.exportField['Readiness'],
									},
								})
							}
						/>
						<Checkbox
							label="2nd Bottle Requested"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'2nd Bottle Requested': !this.state.exportField['2nd Bottle Requested'],
									},
								})
							}
						/>
						<Checkbox
							label="Body"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Body: !this.state.exportField['Body'],
									},
								})
							}
						/>
						<Checkbox
							label="Descriptors"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Descriptors: !this.state.exportField['Descriptors'],
									},
								})
							}
						/>
						<Checkbox
							label="Quality"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Quality: !this.state.exportField['Quality'],
									},
								})
							}
						/>
					</div>
				</div>
				<hr />
				<div className="export_fields">
					<b>Competition & Event features</b>
					<div className="flex start export_options">
						<Checkbox
							label="Judge Name"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Judge Name': !this.state.exportField['Judge Name'],
									},
								})
							}
						/>
						<Checkbox
							label="Judge Medal"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Judge Medal': !this.state.exportField['Judge Medal'],
									},
								})
							}
						/>
						<Checkbox
							label="Start date"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Start date': !this.state.exportField['Start date'],
									},
								})
							}
						/>
						<Checkbox
							label="End date"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'End date': !this.state.exportField['End date'],
									},
								})
							}
						/>
						<Checkbox
							label="Flight Code"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Flight Code': !this.state.exportField['Flight Code'],
									},
								})
							}
						/>
						<Checkbox
							label="Judge e-mail"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Judge e-mail': !this.state.exportField['Judge e-mail'],
									},
								})
							}
						/>
						<Checkbox
							label="Team conclusion"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Team conclusion': !this.state.exportField['Team conclusion'],
									},
								})
							}
						/>
						<Checkbox
							label="Category"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Category: !this.state.exportField['Category'],
									},
								})
							}
						/>
						<Checkbox
							label="Team"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Team: !this.state.exportField['Team'],
									},
								})
							}
						/>
						<Checkbox
							label="Judge tasting note"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Judge tasting note': !this.state.exportField['Judge tasting note'],
										'Best note': !this.state.exportField['Best note'],
									},
								})
							}
						/>
						<Checkbox
							label="Head Judge Conclusion"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										'Head Judge Conclusion': !this.state.exportField['Head Judge Conclusion'],
									},
								})
							}
						/>
						<Checkbox
							label="Trophy"
							onChange={() =>
								this.setState({
									exportField: {
										...this.state.exportField,
										Trophy: !this.state.exportField['Trophy'],
									},
								})
							}
						/>
					</div>
				</div>

				{Object.keys(this.state.exportField).filter((key) => this.state.exportField[key]).length ? (
					<div className="download">
						<Button variant="outlined" size="small" onHandleClick={() => this.onDownload()}>
							Download <FaDownload />
						</Button>
					</div>
				) : (
					<></>
				)}

				{Object.keys(this.state.exportField).filter((key) => this.state.exportField[key]).length ? (
					<table>
						<thead>
							<tr>
								{Object.keys(this.state.exportField)
									.filter((key) => this.state.exportField[key])
									.map((head, key) => (
										<th key={`header-${key}`}>{head}</th>
									))}
							</tr>
						</thead>
						<tbody>
							{exportData.map((data) =>
								data?.impressions?.map((impression, key) => (
									<tr key={`tr-${key}`} className="filter-export">
										{this.state.exportField['Wine Name'] && <td>{impression.name}</td>}
										{this.state.exportField['Flight Code'] && (
											<td>{this.getFlightCode(impression.name)}</td>
										)}
										{this.state.exportField.Team && (
											<td>
												{contest.teams.find((team) => team.ref === impression.team)?.name ?? '-'}
											</td>
										)}
										{this.state.exportField.Category && (
											<td>
												{
													contest.collections.find(
														(collection) => collection.ref === impression.collection
													)?.name
												}
											</td>
										)}
										{this.state.exportField.Trophy && <td>{this.getTrophy(data.statements[0])}</td>}
										{this.state.exportField['Judge Name'] && <td>{impression.creator.name}</td>}
										{this.state.exportField['Judge e-mail'] && <td>{impression.creator.email}</td>}
										{this.state.exportField['Judge tasting note'] && (
											<td>{impression.summary_personal ?? '-'}</td>
										)}
										{this.state.exportField['Best note'] && (
											<td>
												{data.statements[0]?.metadata?.marked_statements?.includes(impression.ref)
													? 'Yes'
													: 'No'}
											</td>
										)}
										{this.state.exportField['Judge Medal'] && (
											<td
												style={
													data.statements[0]?.metadata?.marked_statements?.includes(impression.ref)
														? {fontWeight: 'bold'}
														: {}
												}
											>
												{impression.info.medal}
											</td>
										)}
										{this.state.exportField['Team conclusion'] && (
											<td>{data?.statements[0]?.statement ?? '-'}</td>
										)}
										{this.state.exportField['Head Judge Conclusion'] && (
											<td>{data?.statements[1]?.statement ?? '-'}</td>
										)}
										{this.state.exportField.Region && <td>{impression.region}</td>}
										{this.state.exportField.Country && <td>{data?.mold?.country ?? '-'}</td>}
										{this.state.exportField.Vintage && <td>{impression.vintage}</td>}
										{this.state.exportField['Grape(s)'] && <td>{impression.grape}</td>}
										{this.state.exportField.Price && <td>{impression.price}</td>}
										{this.state.exportField.Currency && <td>{impression.currency}</td>}
										{this.state.exportField.Producer && <td>{impression.producer}</td>}

										{this.state.exportField['Food pairing note'] && (
											<td>{impression.food_pairing}</td>
										)}
										{this.state.exportField.Acidity && <td>{impression.notes['@'][0]}</td>}
										{this.state.exportField.Alcohol && <td>{impression.notes['@'][1]}</td>}
										{this.state.exportField.Body && <td>{impression.notes['@'][2]}</td>}
										{this.state.exportField.Tanins && (
											<td>{impression.notes['@'][impression.notes['@'].length - 1]}</td>
										)}
										{this.state.exportField.Sweetness && (
											<td>{impression.notes['@'][impression.notes['@'].length - 2]}</td>
										)}
										{this.state.exportField.Descriptors && (
											<td>{impression.notes['@'].join(', ')}</td>
										)}
										{this.state.exportField['Start date'] && (
											<td>
												{
													contest.collections.find(
														(collection) => collection.ref === impression.collection
													)?.start_date
												}
											</td>
										)}
										{this.state.exportField['End date'] && (
											<td>
												{
													contest.collections.find(
														(collection) => collection.ref === impression.collection
													)?.end_date
												}
											</td>
										)}
										{this.state.exportField.Quality && (
											<td>{impression.notes['@'][impression.notes['@'].length - 4]}</td>
										)}
										{this.state.exportField.Slider && <td>{this.getSlider(impression.info)}</td>}
										{this.state.exportField.Readiness && (
											<td>{impression.notes['@'][impression.notes['@'].length - 3]}</td>
										)}
										{this.state.exportField['2nd Bottle Requested'] && (
											<td>{data.statements[0]?.requested ? 'Yes' : 'No'}</td>
										)}
									</tr>
								))
							)}
						</tbody>
					</table>
				) : (
					<></>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const getContestInfo = makeContestInfoSelector();
	const contestRef = props.match.params.ref;

	return {
		contest: getContestInfo(state, {contestRef}),
		exportData: state.exportData.data,
		loadingState: contestThemesLoadingSelector(state, {contestRef}),
		resultsState: contestExportsLoadingSelector(state),
	};
};

const mapDispatchToProps = {
	fetchContestToExport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContestExports);
