import React from 'react';
import DropoverLink from 'components/shared/ui/DropoverLink';
import TextInput from 'components/shared/ui/TextInput';
import DropoverInput from 'components/shared/ui/DropoverInput';

const WineInfo = ({wine, onUpdateFields, type, themes}) => (
	<div className="WineInfo__Wrapper">
		<div className="WineInfo__Region">
			<DropoverInput
				label={'Flight'}
				infoKey={'collection'}
				value={themes.find((theme) => theme.ref === wine.collection)?.name || ''}
				options={themes}
				onSelect={(collection) => onUpdateFields(collection.ref, 'collection')}
			/>
		</div>
		<div className="WineInfo__Region">
			<TextInput
				label={'Name'}
				type="text"
				onChange={onUpdateFields}
				value={wine.name}
				infoKey={'name'}
			/>
		</div>
		<div className="WineInfo__Region">
			<TextInput
				label={'Country'}
				type="text"
				onChange={onUpdateFields}
				value={wine.country}
				infoKey={'country'}
			/>
		</div>
		<div className="WineInfo__Region">
			<TextInput
				label={'Region'}
				type="text"
				onChange={onUpdateFields}
				value={wine.region}
				infoKey={'region'}
			/>
		</div>
		<div className="WineInfo__Vintage">
			<TextInput
				label={'Vintage'}
				type="text"
				infoKey={'vintage'}
				onChange={onUpdateFields}
				value={wine.vintage}
			/>
		</div>
		<div className="WineInfo__Vintage">
			<TextInput
				label={'Price'}
				type="text"
				infoKey={'price'}
				onChange={onUpdateFields}
				value={wine.price}
			/>
		</div>
		<div className="WineInfo__Grape">
			<TextInput
				label={'Grape/grape blend'}
				type="text"
				infoKey={'grape'}
				value={wine.grape}
				onChange={onUpdateFields}
			/>
		</div>
	</div>
);

export default WineInfo;
