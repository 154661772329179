import {contestConstants} from 'const';
import {createLoadingSelector} from './requestStatusReducer';
import {format} from 'date-fns';

const defaultData = {
	data: [],
};

export default function reducer(state = defaultData, action) {
	switch (action.type) {
		case contestConstants.FETCH_CONTEST_LOGISTICS_PENDING:
		case contestConstants.FETCH_CONTEST_LOGISTICS_ERROR: {
			let data = Object.assign({}, state, {data: []});

			return data;
		}

		case contestConstants.FETCH_CONTEST_LOGISTICS_SUCCESS: {
			let data = Object.assign({}, state, {data: action.payload.data});

			return data;
		}

		case contestConstants.FETCH_CONTEST_SET_DELIVERED_PENDING:
		case contestConstants.FETCH_CONTEST_SET_DELIVERED_ERROR: {
			return state;
		}

		case contestConstants.FETCH_CONTEST_SET_DELIVERED_SUCCESS: {
			let data = Object.assign({}, state, {
				data: state.data.map((datem) => {
					if (datem.id !== action.payload.id) return datem;
					else {
						datem.delivered = 1;
						datem.delivered_at = format(new Date().toISOString(), 'YYYY-MM-DD HH:mm:ss');
						return datem;
					}
				}),
			});

			return data;
		}

		default:
			return state;
	}
}

export const contestLogisticsLoadingSelector = createLoadingSelector('FETCH_CONTEST_LOGISTICS');
